const dirRoot = '/img/';
const dirHome = '/img/home/';
const dirPartners = '/img/partners/';
const dirAbout = '/img/aboutUs/';
const dirProducts = '/img/products/';

/**
 * Root image paths for the application.
 */
export const rootImages = {
  logo: {
    logotipo: `${dirRoot}LOGO-REDEX.webp`,
  },
  home: {
    banner: `${dirHome}banner.webp`,
  },
  aboutUs: {
    building: `${dirAbout}edif-gr.webp`,
    cover: `${dirAbout}cover-gr.png`,
    team: `${dirAbout}team.webp`,
  },
  products: {
    routers: {
      ac11Tenda1: `${dirProducts}routers/tenda-ac11-1.webp`,
      ac11Tenda2: `${dirProducts}routers/tenda-ac11-2.webp`,
      ac11Tenda3: `${dirProducts}routers/tenda-ac11-3.webp`,
      ac11Tenda4: `${dirProducts}routers/tenda-ac11-4.webp`,
      routerGb: `${dirProducts}routers/router-gigabit.webp`,
      routerTendaAc11: `${dirProducts}routers/router-tenda-ac11.webp`,
      routerTenda: `${dirProducts}routers/ac11-tenda.webp`,
      ac11Box: `${dirProducts}routers/tenda-ac11-box.webp`,
      ac11Box1: `${dirProducts}routers/tenda-ac11-box1.webp`,
      ac11Comp: `${dirProducts}routers/tenda-ac11-comparison.webp`,
      meshMw6: `${dirProducts}routers/mesh-mw6.webp`,
      meshMw6Box: `${dirProducts}routers/mesh-box.webp`,
      meshMw6Box1: `${dirProducts}routers/mesh-box1.webp`,
      meshNova: `${dirProducts}routers/mesh-nova.webp`,
    },
    onu: {
      onuPrew: `${dirProducts}onu/onu1.webp`,
      onuBox: `${dirProducts}onu/onu-box.webp`,
      onu: `${dirProducts}onu/onu.webp`,
      onu1: `${dirProducts}onu/onu-bg.webp`,
      onuNew1: `${dirProducts}onu/onu-1.webp`,
      onuNew2: `${dirProducts}onu/onu-2.webp`,
      onuNew3: `${dirProducts}onu/onu-3.webp`,
      onuNew4: `${dirProducts}onu/onu-4.webp`,
    },
    cables: {
      cablesDrop: `${dirProducts}cables/cables-drop1.webp`,
      cableDrop: `${dirProducts}cables/cable-drop.webp`,
      drop1h1km: `${dirProducts}cables/cable-drop-1h-1km.webp`,
      drop1c1km: `${dirProducts}cables/cable-drop-1c1km.webp`,
      drop1c1km1: `${dirProducts}cables/cable-drop-1c1km-1.webp`,
      drop1c1km2: `${dirProducts}cables/cable-drop-1c1km-2.webp`,
      drop1c1km3: `${dirProducts}cables/cable-drop-1c1km-3.webp`,
      drop1h1km1: `${dirProducts}cables/cable-drop-1h1km1.webp`,
      drop1c2km: `${dirProducts}cables/cable-drop-1c2km.webp`,
      drop1c2km1: `${dirProducts}cables/cable-drop-1c2km-1.webp`,
      drop1h2km: `${dirProducts}cables/cable-drop-1h2km.webp`,
      drop1h2km1: `${dirProducts}cables/cable-drop-1hilo.webp`,
      cdrop1h2km: `${dirProducts}cables/1h2km-drop.webp`,
      cdrop1h2km2: `${dirProducts}cables/cable-drop.webp`,
      drop2h2km: `${dirProducts}cables/cable-drop-2h2km.webp`,
      drop2h2km1: `${dirProducts}cables/cable-drop-2hilos.webp`,
      cdrop2h2km: `${dirProducts}cables/2h2km-drop.webp`,
      drop2c2km: `${dirProducts}cables/cable-drop-2c2km.webp`,
      drop2c2km1: `${dirProducts}cables/cable-drop-2c2km-1.webp`,
      drop2c2km2: `${dirProducts}cables/cable-drop-2c2km-2.webp`,
    },
    ftth: {
      napBoxGfs8g: `${dirProducts}ftth/napBox-gfs8g.webp`,
      napBoxGfs8g1: `${dirProducts}ftth/napbox-gfs8g-1.webp`,
      napBoxGfs8g2: `${dirProducts}ftth/napbox-gfs8g-2.webp`,
      napBoxGfs8g3: `${dirProducts}ftth/napBox-gfs8g-3.webp`,
      napBoxGfs8g4: `${dirProducts}ftth/napBox-gfs8g-4.webp`,
      napBoxGfs8g5: `${dirProducts}ftth/napBox-gfs8g-5.webp`,
      napBoxGfs8g6: `${dirProducts}ftth/napBox-gfs8g-6.webp`,
      napBoxAdc: `${dirProducts}ftth/napBox-ADC-FDB416.webp`,
      napBoxAdc1: `${dirProducts}ftth/napBox-ADC-FDB416-1.webp`,
      napBoxAdc2: `${dirProducts}ftth/napBox-ADC-FDB416-2.webp`,
      napBoxAdc3: `${dirProducts}ftth/napBox-ADC-FDB416-3.webp`,
      napBoxAdc4: `${dirProducts}ftth/napBox-ADC-FDB416-4.webp`,
      napBoxAdc5: `${dirProducts}ftth/napBox-ADC-FDB416-5.webp`,
      napBoxAdc6: `${dirProducts}ftth/napBox-ADC-FDB416-6.webp`,
      patchcord: `${dirProducts}ftth/patchcord-jumper.webp`,
      comboOnuRoseta: `${dirProducts}ftth/combo-onu-roseta.webp`,
      comboOnuRoseta1: `${dirProducts}ftth/combo-onu-roseta1.webp`,
    },
  },
  partners: {
    batemca: `${dirPartners}batemca-logo.webp`,
    ciudadCars: `${dirPartners}ciudad-cars-norte.webp`,
    deca: `${dirPartners}decalogo.webp`,
    destileriaHD: `${dirPartners}destileria-hd.webp`,
    grupoSerex: `${dirPartners}grupo-serex.webp`,
    impexca: `${dirPartners}impexca.webp`,
    premiumMotors: `${dirPartners}premium-motors.webp`,
    proalex: `${dirPartners}proalex.webp`,
    segramar: `${dirPartners}segramar.webp`,
    solcimeca: `${dirPartners}solcimeca.webp`,
    steelfa: `${dirPartners}steelfab.webp`,
    vimar: `${dirPartners}vimar.webp`,
    wideTec: `${dirPartners}wide-tec.webp`,
  },
};
