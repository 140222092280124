"use client";

import { useRef, useState } from "react";

import { ProductsInfo } from "@/helpers";
import { Box, Button, Divider, Stack, Typography, type Theme, useMediaQuery } from "@mui/material";
import Image from "next/image";
import Link from "next/link";

import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { zoomOptions } from "@/common/common";

interface ProductBodyProps {
    product: ProductsInfo;
}

export function ProductBody ({ product } : ProductBodyProps) {

    const [selectedImage, setSelectedImage] = useState(product.imgArr[0]);
    const [openImgModal, setOpenImgModal] = useState(false);

    const imgSlideRef = useRef(0);

    const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));


    /**
     * The images from the current slides that the Lightbox needs to render it
     * Currently used only for mobile view
     */
    const slides: Array<{ src: string }> = product.imgArr.map((img: string) => ({
      src: img,
    }));

    const handleImageClick = (image: string) => {
      setSelectedImage(image);
    };
  
    const handleImgModal = (idx: number) => {
      imgSlideRef.current = idx;
      setOpenImgModal(true);
    };

    return (
      <Stack direction={{ md: 'row' }} rowGap={3} columnGap={3}>
        {/* Sección de imágenes */}
        <Stack
          spacing={3}
          px={3}
          justifyContent={upMd ? 'center' : 'space-evenly'}
          alignItems={'center'}
          direction={'row'}
          marginLeft={  upMd ? 20 : 0  }
        >
          <Stack spacing={1}>
            {product.imgArr.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={product.title}
                width={1000}
                height={750}
                loading={'lazy'}
                sizes='100vw'
                style={{
                  width: upMd ? 50 : 40,
                  height: upMd ? 50 : 40,
                  cursor: 'pointer',
                  objectFit: 'cover',
                  border: image === selectedImage ? '2px solid rgba(27, 129, 239, 1)' : 'none',
                  borderRadius: 10,
                }}
                onClick={() => handleImageClick(image)}
              />
            ))}
          </Stack>
          <Box>
            <Image
              src={selectedImage}
              alt={product.title}
              width={500}
              height={375}
              loading={'lazy'}
              sizes='100vw'
              style={{
                width: '70vw',
                height: 'auto',
                maxWidth: 400,
                maxHeight: 500,
                cursor: 'zoom-in',
              }}
              onClick={() => handleImgModal(product.imgArr.indexOf(selectedImage))}
            />
          </Box>
        </Stack>
        <Divider
          orientation={upMd ? 'vertical' : 'horizontal'}
          sx={{
            border: '2px solid secondary.light',
            height: upMd ? '50vw' : '100%',
            maxHeight: '30rem',
          }}
        />
        {/* Sección de contenido del producto */}
        <Stack p={upMd ? 2 : 1} spacing={3}>
          <Stack spacing={1} alignItems={upMd ? 'flex-start' : 'center'}>
            <Typography variant='h4' fontWeight={500}>
              {product.title}
            </Typography>
            <Typography variant='h5' fontWeight={500}>
              {product.subtitle}
            </Typography>
          </Stack>
          <Stack>
            {typeof product.description !== 'string' &&
              product.description.map((desc, idx) => (
                <Typography key={idx} paragraph variant='subtitle2'>
                  {desc}
                </Typography>
            ))}
          </Stack>
          {typeof product.price === 'string' ? (
            <Typography variant='h6'>Precio: {product.price}</Typography>
          ) : (
            <Stack>
              <Typography variant='h6'>Precio al detal: {product.price.retail}</Typography>
              <Typography variant='h6'>Precio mayorista: {product.price.wholesale}</Typography>
            </Stack>
          )}
          <Typography paragraph fontStyle={'italic'} fontSize={['.9em', '1em']}>
            Precios NO incluyen impuestos (IVA, IGTF)
          </Typography>

          <Link href={'/about-us/#contact'}>
            <Button
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                ':hover': { bgcolor: 'primary.main', color: 'white' },
                textTransform: 'capitalize',
              }}
            >
              Comprar
            </Button>
          </Link>
        </Stack>
        {/* Modal Image for zoom view */}
        {openImgModal && (
          <Lightbox
            index={imgSlideRef.current}
            open={openImgModal}
            close={() => setOpenImgModal(false)}
            slides={slides}
            plugins={[Zoom]}
            zoom={zoomOptions}
          />
        )}
      </Stack>
    )
}