import { Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';

// dynamic imports
const RevealAnimation = dynamic(async () => {
  const { RevealAnimation } = await import('../common/RevealAnimation');
  return { default: RevealAnimation };
});

const ReusableSlider = dynamic(async () => {
  const { ReusableSlider } = await import('../reusable/ReusableSlider');
  return { default: ReusableSlider };
});

export function Reviews() {
  const reviews = [
    {
      name: 'Productora de Alimentos Serex C.A (PROALEX)',
      review: `Global Redex es nuestro proveedor predilecto para adecuaciones e instalaciones en nuestra
      infraestructura de telecomunicaciones en todas nuestras sedes operativas. Estamos agradecidos
      con su respuesta y gestión oportuna ante todos nuestros requerimientos.`,
    },
    {
      name: 'Diego Gonzalez',
      review: `En efecto, recibí la recomendación de especialistas de Global Redex, compré el Router Tenda AC11
      y fue la solución definitiva a las fluctuaciones de Internet en mi hogar. Con el nuevo router, puedo
      moverme por toda la casa sin perder señal ni velocidad, es una maravilla.`,
    },
    {
      name: 'PRONETLA, C.A',
      review: `Con Global Redex pudimos encontrar y adquirir el modelo de ONU que estuvimos buscando, son
      los únicos a nivel nacional en ofrecer la ONU HUAWEI HG8310M sin logotipo, el cual para nosotros
      es un plus importante. Y el precio, incomparable.`,
    },
    {
      name: 'Maria Acosta',
      review: `Yo adquirí un TENDA AC11, pues se adapta perfectamente a lo que necesito. Instale un servicio de
      Internet por fibra óptica y funciona increíble. Estoy satisfecha con mi compra y lo recomendare a
      todos mis conocidos.`,
    },
    {
      name: 'Gustavo Mendez',
      review: `Soy instalador de servicios de fibra óptica, y soy comprador frecuente de Global Redex, son mi
      proveedor de suministros para mis instalaciones porque me ofrecen precios muy buenos y
      siempre cuentan con la disponibilidad que necesito como instalador.`,
    },
    {
      name: 'POWER LINK CORP C.A',
      review: `Las ONU con mejor desempeño que hemos adquirido han sido las Huawei HG8310M adquiridas a
      Global Redex, El pago fue sencillo, el envío a nuestra ciudad fue rápida y en general estamos muy
      satisfechos.`,
    },
  ];

  return (
    <Stack component={'section'} bgcolor={'primary.dark'} py={4}>
      <RevealAnimation width='100%'>
        <Typography variant='h4' fontWeight={500} textAlign={'center'}>
          Reseñas de nuestros clientes
        </Typography>
      </RevealAnimation>

      <Stack mx={'auto'}>
        <ReusableSlider content={reviews} contentType='reviews' interval={10000} />
      </Stack>
    </Stack>
  );
}
