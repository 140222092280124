'use cliente';

import { Box } from '@mui/material';
import { Footer, NavbarHome } from '.';

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
}

function HomeLayout({ children }: LayoutProps) {
  return (
    <Box sx={{ overflowX: 'hidden' }} mx={'auto'}>
      <NavbarHome />
      {children}
      <Footer />
    </Box>
  );
}
export default HomeLayout;
