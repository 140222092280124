import { rootImages } from '@/core';
import { Box, Stack, Typography, type SxProps } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { CompanyInfoCard, Contact, OurTeam } from '.';

const RevealAnimation = dynamic(async () => {
  const { RevealAnimation } = await import('../common/RevealAnimation');
  return { default: RevealAnimation };
});

export function AboutUs() {
  return (
    <Box
      component={'section'}
      sx={{
        bgcolor: 'white',
        py: 3,
      }}
    >
      {/* Company information */}
      <RevealAnimation width='100%'>
        <Stack direction={{ md: 'row' }} sx={containerStyled} marginTop={ 10 }>
          <Image
            src={rootImages.aboutUs.building}
            alt='Global-Redex-Building'
            width={600}
            height={450}
            sizes='100vw'
            loading={'lazy'}
            style={{
              minWidth: '15rem',
              width: '80vw',
              maxWidth: '50rem',
              minHeight: 300,
              height: 'auto',
              maxHeight: 500,
              objectFit: 'cover',
            }}
          />
          <Stack spacing={2} maxWidth={'700px'}>
            <Typography variant='h5' fontWeight={700}>
              Sobre nosotros
            </Typography>
            <Typography variant='h6'>
              Somos una empresa proveedora de servicios de telecomunicaciones, fundada en la ciudad
              de Maracaibo en el año 2008. Reunimos un equipo de profesionales en el área del
              networking e infraestructuras de red de telecomunicaciones con mas de 30 años de
              experiencia.
            </Typography>
          </Stack>
        </Stack>
      </RevealAnimation>

      {/* Mision & Vision Section */}
      <Stack bgcolor={'primary.main'} p={5}>
        <Stack direction={{ md: 'row' }} justifyContent={'center'} alignItems={'center'} gap={5}>
          <RevealAnimation width='100%'>
            <CompanyInfoCard
              text='Diseñar y ofrecer soluciones de conectividad en telecomunicaciones, eficaces y eficientes, para ayudar
            a nuestros clientes en el desarrollo de sus procesos.'
              title='Nuestra misión'
            />
          </RevealAnimation>

          <RevealAnimation width='100%'>
            <CompanyInfoCard
              text='Posicionarnos como la empresa lider, mas confiable y reconocida en el mercado nacional de
            telecomunicaciones.'
              title='Nuestra visión'
            />
          </RevealAnimation>
        </Stack>
      </Stack>

      <OurTeam />

      {/* Contact section */}
      <RevealAnimation width='100%'>
        <Contact />
      </RevealAnimation>
    </Box>
  );
}

const containerStyled: SxProps = {
  alignItems: 'center',
  justifyContent: 'center',
  mx: 'auto',
  p: 5,
  rowGap: 10,
  columnGap: 10,
  color: 'black',
  backgroundImage: 'linear-gradient(125deg, rgba(255,255,255,1) 80%, rgba(169,169,169,1) 100%)',
};
