import { rootImages } from '@/core';

interface PartnersLogosI {
  alt: string;
  height: number;
  src: string;
  width: number;
}

/**
 * An array with the Partner's logo images of the company
 * @type {PartnersLogosI[]}
 */
export const partnersLogos: PartnersLogosI[] = [
  {
    alt: 'BATEMCA',
    height: 150,
    src: rootImages.partners.batemca,
    width: 200,
  },
  {
    alt: 'Ciudad Cars Norte',
    height: 150,
    src: rootImages.partners.ciudadCars,
    width: 200,
  },
  {
    alt: 'DECA',
    height: 100,
    src: rootImages.partners.deca,
    width: 300,
  },
  {
    alt: 'Destilería HD',
    height: 150,
    src: rootImages.partners.destileriaHD,
    width: 350,
  },
  {
    alt: 'Grupo Serex',
    height: 100,
    src: rootImages.partners.grupoSerex,
    width: 300,
  },
  {
    alt: 'Impex C.A.',
    height: 100,
    src: rootImages.partners.impexca,
    width: 300,
  },
  {
    alt: 'Proalex',
    height: 100,
    src: rootImages.partners.proalex,
    width: 350,
  },
  {
    alt: 'Segramar',
    height: 100,
    src: rootImages.partners.segramar,
    width: 400,
  },
  {
    alt: 'Solcimeca',
    height: 100,
    src: rootImages.partners.solcimeca,
    width: 300,
  },
  {
    alt: 'STEELFA',
    height: 100,
    src: rootImages.partners.steelfa,
    width: 300,
  },
  {
    alt: 'Vimar',
    height: 120,
    src: rootImages.partners.vimar,
    width: 300,
  },
  {
    alt: 'Wide Tec',
    height: 100,
    src: rootImages.partners.wideTec,
    width: 300,
  },
];
