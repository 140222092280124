import { NextAppDirEmotionCacheProvider } from '@/app/EmotionCache';
import { ThemeProvider } from '@mui/material';
import { responsiveTheme } from '.';

export function ThemeRegistry({ children }: { children: React.ReactNode | JSX.Element }) {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'mui' }}>
      <ThemeProvider theme={responsiveTheme}>{children}</ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
