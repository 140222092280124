'use client';
import { partnersLogos } from '@/helpers';
import '@/styles/imageSlider.css';
import { Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';

// dynamic imports
const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function ImageSlider() {
  return (
    <Stack component={'main'} bgcolor={'white'} pt={3}>
      <RevealAnimation width='100%'>
        <Typography variant='h4' color={'black'} textAlign={'center'} fontWeight={500}>
          Nuestros Clientes
        </Typography>
      </RevealAnimation>

      <div id='logos-home-page'>
        <div className='slider'>
          <RevealAnimation width='100%'>
            <div className='slide-track'>
              {partnersLogos.map((image, index) => (
                <div className='slide' key={index}>
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                    sizes='100vw'
                    style={{
                      maxWidth: 400,
                      maxHeight: 487,
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ))}
              {partnersLogos.map((image, index) => (
                <div className='slide' key={index}>
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                    sizes='100vw'
                    style={{
                      maxWidth: 400,
                      maxHeight: 487,
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ))}
            </div>
          </RevealAnimation>
        </div>
      </div>
    </Stack>
  );
}
