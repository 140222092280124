'use client';
import { Call, LocationOn, Mail } from '@mui/icons-material';
import { Box, Divider, Link, Stack, Typography, useMediaQuery, type Theme } from '@mui/material';

interface CustomTypographyProps {
  text: string;
  text1?: string;
  text2?: string;
  mail?: boolean;
}

export function Contact() {
  // breakpoints
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  // reusable components
  const CustomStack = ({ icon, text }: { icon: JSX.Element; text: string }) => (
    <Stack
      direction={'row'}
      spacing={[3, 1]}
      border={'2px solid'}
      borderColor={'primary.main'}
      borderRadius={'12px'}
      alignItems={'center'}
      p={'8px 12px'}
      width={['12rem', 'auto']}
      sx={{
        ':hover': {
          bgcolor: 'primary.main',
          color: 'white',
          transition: '0.3s',
        },
      }}
    >
      <Stack
        alignItems={'center'}
        bgcolor={'primary.main'}
        borderRadius={'50%'}
        width={'fit-content'}
        p={0.5}
      >
        {icon}
      </Stack>
      <Typography variant='h6' fontWeight={500} textAlign={'center'}>
        {text}:
      </Typography>
    </Stack>
  );

  const CustomText = ({ text, text1, text2, mail }: CustomTypographyProps) => (
    <Box maxWidth={['12rem', '10rem', '10rem', '12rem']} px={[0, 1, 2]} mx={'auto'}>
      {mail ? (
        <Typography
          sx={{
            color: 'secondary.dark',
            fontWeight: 500,
            fontSize: ['.9em', '.80em', '.9em'],
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: upMd ? 'row' : 'column',
            rowGap: !upSm ? 1 : 0,
            columnGap: 1,
          }}
        >
          {text}
          <Link href={`mailto:${text1}`} sx={{ textDecoration: 'none' }}>
            {text1}
          </Link>
        </Typography>
      ) : (
        <Typography
          sx={{
            color: 'secondary.dark',
            fontWeight: 500,
            fontSize: ['.9em', '.80em', '.9em'],
          }}
        >
          {text}
        </Typography>
      )}
      {text1 && !mail && (
        <Typography
          sx={{
            color: 'secondary.dark',
            fontWeight: 500,
            fontSize: ['.9em', '.80em', '.9em'],
          }}
        >
          {text1}
        </Typography>
      )}
      {text2 && (
        <Typography
          sx={{
            color: 'secondary.dark',
            fontWeight: 500,
            fontSize: ['.9em', '.80em', '.9em'],
          }}
        >
          {text2}
        </Typography>
      )}
    </Box>
  );

  return (
    <Stack
      component={'section'}
      id='contact'
      spacing={2}
      sx={{
        py: 3,
        bgcolor: 'white',
        color: 'black',
      }}
    >
      <Typography variant='h3' textAlign={'center'}>
        Contáctanos
      </Typography>

      <Stack>
        {/* Contact Us section layout when it's down to 600px */}
        {!upSm && (
          <Stack spacing={3} mx={'auto'}>
            <Stack spacing={2} alignItems={'center'} width={'70vw'} px={2}>
              <CustomStack text='Dirección' icon={<LocationOn sx={{ color: 'white' }} />} />
              <CustomText
                text={'Av. 2 El Milagro Cl. 89A, Sector Santa Lucia.'}
                text1={'Al lado de Capitanía de Puerto, a cien metros de DeCandido Express.'}
                text2={'Maracaibo, Zulia, Venezuela.'}
              />
            </Stack>
            <Stack spacing={2} alignItems={'center'} width={'70vw'}>
              <CustomStack text='Teléfonos' icon={<Call sx={{ color: 'white' }} />} />
              <CustomText
                text={'Telf #1: 0414-0594708'}
                text1={'Telf #2: 0414-6551057'}
                text2={'Telf #3: 0414-9660958'}
              />
            </Stack>
            <Stack spacing={2} alignItems={'center'} width={'70vw'}>
              <CustomStack text='Correo' icon={<Mail sx={{ color: 'white' }} />} />
              <CustomText text={'Contáctanos:'} text1={'info@globalredex.com'} mail={true} />
              <CustomText text={'Líder:'} text1={'amorales@globalredex.com'} mail={true} />
              <CustomText text={'Gerencia:'} text1={'grh@globaredex.com'} mail={true} />
            </Stack>
          </Stack>
        )}

        {/* Contact Us section layout when it's up to 600px */}
        {upSm && (
          <Box mx={'auto'}>
            <Stack
              direction={'row'}
              spacing={[0, 2, 5, 5]}
              maxWidth={'80vw'}
              justifyContent={'start'}
            >
              <CustomStack text='Dirección' icon={<LocationOn sx={{ color: 'white' }} />} />
              <CustomStack text='Teléfonos' icon={<Call sx={{ color: 'white' }} />} />
              <CustomStack text='Correo' icon={<Mail sx={{ color: 'white' }} />} />
            </Stack>
            <Divider sx={{ my: 2, color: 'secondary.dark' }} />
            <Stack
              direction={'row'}
              maxWidth={'80vw'}
              justifyContent={'center'}
              columnGap={[0, 2, 6, 2]}
            >
              <CustomText
                text={'Av. 2 El Milagro Cl. 89A, Sector Santa Lucia.'}
                text1={'Al lado de Capitanía de Puerto, a 100m de DeCandido Express.'}
                text2={'Maracaibo, Zulia, Venezuela.'}
              />
              <CustomText
                text={'Telf #1: 0414-0594708'}
                text1={'Telf #2: 0414-6551057'}
                text2={'Telf #3: 0414-9660958'}
              />
              <Stack>
                <CustomText text={'Contacto:'} text1={'info@globalredex.com'} mail={true} />
                <CustomText text={'Líder:'} text1={'amorales@globalredex.com'} mail={true} />
                <CustomText text={'Gerencia:'} text1={'grh@globaredex.com'} mail={true} />
              </Stack>
            </Stack>
          </Box>
        )}
        <Divider sx={{ my: 2, color: 'secondary.dark' }} />

        {/* Google Maps location */}
        <Box display='flex' justifyContent='center' alignItems='center' height='50vh' mt={2}>
          {/* <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1960.4506422639427!2d-71.61177717812545!3d10.664769984018747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8998e67c6806ad%3A0xcfba7258b71151ba!2sEdificio%20Banco%20Industria%20Grupo%20Serex!5e0!3m2!1ses!2sve!4v1694656856715!5m2!1ses!2sve'
            width='400'
            height='450'
            style={{
              border: '1px solid gray',
              borderRadius: 10,
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              width: !upSm ? '90vw' : '70vw',
            }}
            allowFullScreen={false}
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade' */}
          {/* /> */}
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3921.1368775310852!2d-71.60355762495998!3d10.64647288949453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDM4JzQ3LjMiTiA3McKwMzYnMDMuNSJX!5e0!3m2!1sen!2sve!4v1696371792185!5m2!1sen!2sve'
            width='400'
            height='450'
            style={{
              border: '1px solid gray',
              borderRadius: 10,
              boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
              width: !upSm ? '90vw' : '70vw',
            }}
            allowFullScreen={false}
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        </Box>
      </Stack>
      <Stack />
    </Stack>
  );
}
