'use client';
import { rootImages } from '@/core';
import { responsiveTheme } from '@/theme';
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import { Box, Divider, Link, Stack, Typography, type SxProps } from '@mui/material';
import Image from 'next/image';
import { RevealAnimation } from '..';

interface SocialLinkProp {
  href: string;
  icon: JSX.Element;
  name: string;
}

export function Footer() {
  const palette = responsiveTheme.palette;

  /**
   * A reusable component for the social media links of the company
   * @return {JSX.Element} A Link component with the data of the RRSS to redirect
   */
  const SocialLink = ({ href, icon, name }: SocialLinkProp): JSX.Element => {
    const handleMouseOver = (e: {
      currentTarget: { style: { transform: string; color: string } };
    }) => {
      e.currentTarget.style.transform = 'scale(1.2)';
      e.currentTarget.style.color = palette.custom.moss;
    };

    const handleMouseOut = (e: {
      currentTarget: { style: { transform: string; color: string } };
    }) => {
      e.currentTarget.style.transform = 'scale(1)';
      e.currentTarget.style.color = palette.primary.main;
    };

    return (
      <Link
        href={href}
        target={'_blank'}
        rel='noopener noreferrer'
        sx={linkStyles}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        aria-label={name}
      >
        {icon}
      </Link>
    );
  };

  return (
    <footer>
      <Stack sx={style}>
        <Stack spacing={2} component={'section'}>
          {/* Company logo */}
          <Stack maxWidth={'100%'}>
            <RevealAnimation width='100%'>
              <Image src={rootImages.logo.logotipo} alt='Global Redex' width={180} height={90} />
            </RevealAnimation>
          </Stack>

          {/* Social Media icons */}
          <RevealAnimation width='100%'>
            <Stack direction={'row'} justifyContent={'center'} spacing={2}>
              <SocialLink
                href='https://www.facebook.com/globalredex/'
                icon={<Facebook />}
                name={'Facebook'}
              />
              <SocialLink
                href='https://www.instagram.com/globalredex/'
                icon={<Instagram />}
                name={'Instagram'}
              />
              <SocialLink href='https://wa.me/584140594708' icon={<WhatsApp />} name={'WhatsApp'} />
            </Stack>
          </RevealAnimation>
        </Stack>

        <Divider sx={{ border: `1px solid ${palette.secondary.light}`, width: '100%' }} />
        {/* Copyright space */}
        <Box component={'section'}>
          <RevealAnimation width='100%'>
            <Typography textAlign={'center'} color={'secondary.light'}>
              Copyright© — Global Redex { new Date().getFullYear() }
            </Typography>
          </RevealAnimation>
        </Box>
      </Stack>
    </footer>
  );
}

const style: SxProps = {
  justifyContent: 'center',
  alignItems: 'center',
  p: 3,
  rowGap: 3,
  bgcolor: 'white',
  backgroundImage: 'radial-gradient(circle, rgba(255,255,255,1) 70%, rgba(169,169,169,1) 100%)',
};

const linkStyles: SxProps = {
  textDecoration: 'none',
  transition: 'transform 0.2s',
  color: 'primary.main',
};
