import { type AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { format } from 'url';

interface NavLinksI {
  label: string;
  href: string;
  subNavs?:object[];
}

/**
 * Array with tyhe navigation links of the app
 */
export const navLinks: NavLinksI[] = [
  { label: 'Inicio', href: '/' },
  { label: 'Quiénes somos', href: '/about-us' },
  { label: 'Productos', href: '/products' },
  { label: 'Servicios', href: '#services' },
  { label: 'Contacto', href: '/about-us/#contact' },
];

/**
 * Array with the products navigation links (subroutes of products)
 */
export const productsNavLinks: NavLinksI[] = [
  { label: 'Cables Drop', href: '/products/category/cables', subNavs: [  { label: "prueba", href: "" }  ] },
  { label: 'Routers', href: '/products/category/routers' },
  { label: 'ONU', href: '/products/category/onu' },
  { label: 'FTTH', href: '/products/category/ftth' },
];

/**
 * Scrolls the page to a specified section with a given hash.
 * @param {string} hash - The hash value representing the target section.
 */
const scrollToSection = (hash: string) => {
  if (hash.startsWith('#')) {
    const targetElement = document.querySelector(hash);

    if (targetElement) {
      window.scrollTo({
        behavior: 'smooth',
        top: targetElement.getBoundingClientRect().top + window.scrollY,
      });
    }
  }
};

/**
 * Handles hash links in navigation.
 * @param {Object} event - The event object.
 * @param {string} href - The target href value.
 * @param {AppRouterInstance} router - The Next.js router instance.
 * @param {URLSearchParams} searchParams - The URL search parameters.
 */
export const handleHashLinks = (
  event: { preventDefault: () => void },
  href: string,
  router: AppRouterInstance,
  searchParams: URLSearchParams
) => {
  event.preventDefault();

  // Verify if href is an absolute route
  if (!href.startsWith('#')) {
    // If it's true, redirect  redirect to the desired page
    router.push(href);
  } else {
    // If it's a route with hash, use the current logic
    const hash = href;
    const item = searchParams.get(hash);

    if (window.location.pathname !== '/') {
      const routerObj = {
        pathname: '/',
        query: { resume: true, item },
      };

      // Convert the object routerObj into a format URL
      const formattedUrl = format(routerObj);

      // Then, pass the URL formatted as a string to router.push
      router.push(formattedUrl);

      setTimeout(() => scrollToSection(hash), 100);
    } else setTimeout(() => scrollToSection(hash), 100);
  }
};
