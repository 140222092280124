'use client';
import { Paper, Stack, Typography, useMediaQuery, type SxProps, type Theme } from '@mui/material';

interface InfoCardProps {
  icon: JSX.Element | string;
  text: string;
  title: string;
}

export function InfoCard({ icon, text, title }: InfoCardProps) {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  const containerStyled: SxProps = {
    color: 'black',
    bgcolor: 'white',
    borderRadius: 3,
    maxWidth: '25rem',
    minHeight: '14rem',
    height: !md ? '15rem' : '100%',
    p: !md ? '20px 16px' : 2,
    mx: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    },
  };

  return (
    <main>
      <Paper sx={containerStyled}>
        <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
          <>{icon}</>
          <Stack
            spacing={1}
            // height={['15vh', '20vh', '12vh']}
          >
            <Typography fontWeight={500} fontSize={['1.2em', '1.3em']}>
              {title}
            </Typography>
            <Typography color={'secondary.dark'} fontSize={['.95em', '1em', '1.1em']}>
              {text}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </main>
  );
}
