import { rootImages } from '@/core';
import { ProductSection } from '..';

export function FeaturedProduct() {
  return (
    <>
      {/* First Featured Product  */}
      <ProductSection
        bgcolor='primary.dark'
        btnTxt='Conoce más'
        btnBgColor='white'
        btnTxtColor='primary.dark'
        desc='Nuestra marca Global Redex, en cables Drop, es uno de los preferidos en el mercado nacional pues
        lo construimos con triple metal en su estructura, resistente a altas tensiones mecánicas, flamas,
        incluso a curvatura menores a los 2 cm. Su estándar de construcción lo vuelve ideal para las nuevas
        instalaciones de servicios de Internet de Fibra óptica de 1 Gigabit de velocidad tanto residenciales
        como servicios dedicados empresariales.'
        imgSrc={rootImages.products.cables.cablesDrop}
        slug='cables'
        subtitle='Autosoportado'
        title='Cables Drop'
        txtColor='white'
      />

      {/* Second Featured Product  */}
      <ProductSection
        bgcolor='white'
        bgGradient='linear-gradient(125deg, rgba(169,169,169,1) 0%, rgba(255,255,255,1) 30%)'
        btnTxt='Conoce más'
        desc='Hemos considerado los principales requerimientos de nuestros clientes en el transcurso de los años.
        Por esto, hemos traído al mercado venezolano el Router Wi-Fi con la mejor relación en desempeño
        y precio, siendo este el Tenda AC11, rompe muros, doble banda, Gigabit. Un conjunto de absoluta
        potencia en solo equipo y por precios insuperables.'
        imgSrc={rootImages.products.routers.routerTendaAc11}
        slug='routers'
        reverseR={true}
        subtitle='Tenda AC11'
        title='Router Gigabit'
        txtColor='black'
      />
    </>
  );
}
