'use client';
import {
  Assessment,
  Cable,
  ElectricBolt,
  Lan,
  PrecisionManufacturing,
  Wifi,
} from '@mui/icons-material';
import { Grid, Stack, Typography, useMediaQuery, type Theme } from '@mui/material';
import dynamic from 'next/dynamic';
import { InfoCard } from '..';

interface ServicesI {
  icon: JSX.Element | string;
  title: string;
  text: string;
}

const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function OurServices() {
  // breakpoints
  const upXl = useMediaQuery((theme: Theme) => theme.breakpoints.up(1650));
  const xl = useMediaQuery((theme: Theme) => theme.breakpoints.between('xl', 1650));

  // styles
  const sx = { marginX: xl && !upXl ? '-20px' : '-50px' };

  const services: ServicesI[] = [
    {
      icon: <Assessment sx={{ color: 'primary.main' }} />,
      text: 'Diagnóstico y optimización de infraestructura de redes alámbricas e inalámbricas',
      title: 'Evaluación',
    },
    {
      icon: <Lan sx={{ color: 'primary.main' }} />,
      text: 'De infraestructura de red corporativa e industrial; ethernet, wireless o fibra óptica',
      title: 'Instalación',
    },
    {
      icon: <PrecisionManufacturing sx={{ color: 'primary.main' }} />,
      text: 'De materiales de telecomunicaciones en general. Equipos activos, pasivos y fibra óptica',
      title: 'Suministro',
    },
    {
      icon: <Cable sx={{ color: 'primary.main' }} />,
      text: `Suministramos todo tipo de materiales para instalaciones de redes ópticas y ethernet, 
      desde ONUs hasta OLT, Routers y Cajas de empalmes`,
      title: 'Fibra óptica y Ethernet',
    },
    {
      icon: <Wifi sx={{ color: 'primary.main' }} />,
      text: 'Disponibilidad de antenas y radios de alta envergadura según sus necesidades técnicas de campo',
      title: 'Wireless',
    },
    {
      icon: <ElectricBolt sx={{ color: 'primary.main' }} />,
      text: 'Inversores, cargadores, baterías de ciclo profundo, UPS, Transfers, plantas eléctricas y más',
      title: 'Energía',
    },
  ];

  return (
    <Stack
      component={'section'}
      id='services'
      bgcolor={'primary.dark'}
      color={'black'}
      rowGap={5}
      p={5}
    >
      <RevealAnimation width='100%'>
        <Typography variant='h3' fontWeight={500} color={'white'} textAlign={'center'}>
          Nuestros Servicios
        </Typography>
      </RevealAnimation>

      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        columnSpacing={3}
        rowSpacing={3}
      >
        {services.map((serv: ServicesI, idx: number) => (
          <Grid key={idx} item xs={12} sm={6} lg={4} sx={!xl && !upXl ? {} : sx}>
            <RevealAnimation width='100%'>
              <InfoCard icon={serv.icon} text={serv.text} title={serv.title} />
            </RevealAnimation>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
