'use client';
// import { responsiveTheme } from '@/theme';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  MobileStepper,
  Stack,
  Typography,
  useMediaQuery,
  type SxProps,
  type Theme,
} from '@mui/material';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

interface ReusableSliderProps {
  content: Array<Record<string, any>>;
  contentType: string;
  interval?: number;
}

// dynamic imports
const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

const ProductCard = dynamic(
  async () => await import('./ProductCard').then((module) => module.ProductCard)
);

export function ReusableSlider({ content, contentType, interval = 5000 }: ReusableSliderProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [fade, setFade] = useState(true);

  // breakpoints
  const upFourHundred = useMediaQuery((theme: Theme) => theme.breakpoints.up(400));

  // styles
  const mobileStepperSx: SxProps = {
    // position: 'relative',
    // bottom: contentType === 'products' && upFourHundred ? '20rem' : '25vh',
    margin: '0px auto',
    bgcolor: 'transparent',
    color: contentType === 'products' ? 'primary.main' : 'white',
    borderRadius: '30%',
    ':hover': {
      bgcolor: 'transparent',
      color: contentType === 'products' ? 'custom.moss' : 'white',
    },
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % content.length);
    setFade(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? content.length - 1 : prevActiveStep - 1
    );
    setFade(true);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(false);
      setTimeout(() => handleNext(), 200);
    }, interval);

    return () => clearInterval(timer);
  }, [activeStep]);

  const maxSteps = content.length;

  return (
    <Box>
      <Fade in={fade}>
        {contentType === 'products' ? (
          <Stack
            direction={'row'}
            spacing={10}
            alignItems={'center'}
            justifyContent={'center'}
            mt={5}
          >
            <ProductCard
              image={content[activeStep].image}
              title={content[activeStep].title}
              inHome={true}
            />
          </Stack>
        ) : (
          <Stack maxWidth={'45rem'} mx={'auto'} my={3}>
            <RevealAnimation>
              <Stack component={'article'} textAlign='center' spacing={2} px={4}>
                <Typography
                  height={upFourHundred ? '' : '20vh'}
                  minHeight={upFourHundred ? '10rem' : '16rem'}
                  fontSize={['1em', '1.3em']}
                >
                  {content[activeStep].review}
                </Typography>
                <Typography fontWeight={500}>{`— ${content[activeStep].name}`}</Typography>
              </Stack>
            </RevealAnimation>
          </Stack>
        )}
      </Fade>
      <MobileStepper
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        sx={{
          bgcolor: 'transparent',
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: contentType === 'products' ? 'primary.main' : 'white',
          },
        }}
        nextButton={
          <Button
            aria-label='slider next-button'
            sx={{
              ...mobileStepperSx,
              left: contentType === 'products' ? '4rem' : '5vw',
            }}
            onClick={handleNext}
          >
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            aria-label='slider previous-button'
            sx={{
              ...mobileStepperSx,
              right: contentType === 'products' ? '4rem' : '5vw',
            }}
            onClick={handleBack}
          >
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </Box>
  );
}
