'use client';
import { Button, Stack, Typography, useMediaQuery, type Theme } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

interface ProductSectionProps {
  bgcolor: string;
  bgGradient?: string;
  btnBgColor?: string;
  btnTxt: string;
  btnTxtColor?: string;
  desc: string;
  imgSrc: string;
  slug: string;
  reverseR?: boolean;
  subtitle?: string;
  title: string;
  txtColor: string;
}

const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function ProductSection({
  bgcolor,
  bgGradient,
  btnBgColor,
  btnTxt,
  btnTxtColor,
  desc,
  imgSrc,
  slug,
  reverseR,
  subtitle,
  title,
  txtColor,
}: ProductSectionProps) {
  // breakpoints / media queries
  // const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const upXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const mdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

  return (
    <Stack
      component={'section'}
      direction={{
        xs: 'column',
        md: reverseR ? 'row-reverse' : 'row',
      }}
      sx={{
        bgcolor,
        backgroundImage: bgGradient,
        color: txtColor,
        maxHeight: '100%',
      }}
    >
      {/* Image Section */}
      <Image
        src={imgSrc}
        alt={title}
        width={0}
        height={0}
        sizes='100vw'
        priority
        style={{
          width: sm ? '100vw' : '50vw',
          maxWidth: upMd ? 895 : 841,
          height: 'auto',
          maxHeight: upMd ? 1091 : 2015,
        }}
      />

      {/* Content Section */}
      <Stack
        component={'article'}
        flexGrow={1}
        justifyContent='space-evenly'
        alignItems='center'
        mx={'auto'}
        maxWidth={mdAndLg ? '400px' : 'fit-content'}
        px={mdAndLg ? 0 : 4}
        py={!upMd ? 6 : 1}
        spacing={!upXl ? 4 : 0}
      >
        <RevealAnimation width='100%'>
          <Stack rowGap={1}>
            <Typography variant='h3' fontWeight={700} align={upMd ? 'center' : 'left'}>
              {title}
            </Typography>

            <Typography
              variant='h4'
              fontWeight={500}
              fontSize={['1.3em', '1.7em']}
              align={upMd ? 'center' : 'left'}
            >
              {subtitle}
            </Typography>
          </Stack>
        </RevealAnimation>

        <RevealAnimation width='fit-content'>
          <Typography
            paragraph
            fontWeight={500}
            maxWidth={!upXl ? '600px' : '700px'}
            fontSize={{ xs: '1.1em', sm: '1.3em', md: '1.2em', xl: '1.5em' }}
          >
            {desc}
          </Typography>
        </RevealAnimation>

        <RevealAnimation>
          <Button
            variant='contained'
            sx={{
              color: btnTxtColor,
              bgcolor: btnBgColor,
              ':hover': { color: btnTxtColor, bgcolor: btnBgColor },
              fontWeight: 700,
            }}
          >
            <Link href={`/products/category/${slug}`}>{btnTxt}</Link>
          </Button>
        </RevealAnimation>
      </Stack>
    </Stack>
  );
}
