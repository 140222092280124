'use client';
import { rootImages } from '@/core';
import { handleHashLinks, navLinks, productsNavLinks } from '@/helpers';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  type SxProps,
} from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useState, type CSSProperties } from 'react';

interface Props {
  handleCloseDrawer: () => void;
}

const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function Sidebar({ handleCloseDrawer }: Props) {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  // location
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  const toggleSubMenu = () => setOpenSubMenu(!openSubMenu);

  return (
    <>
      <Box mx={'auto'}>
        <Image src={rootImages.logo.logotipo} alt='Global-Redex' width={180} height={90} priority />
      </Box>
      <Divider sx={{ color: 'secondary.main' }} />
      <RevealAnimation>
        <Stack component={'nav'} sx={navStyle}>
          {navLinks.map((link, idx) => (
            <div key={idx}>
              {link.label === 'Productos' ? (
                <>
                  <Stack direction={'column'}>
                    <Stack
                      onClick={toggleSubMenu}
                      sx={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        flexDirection: 'row',
                        columnGap: 1,
                        alignItems: 'center',
                      }}
                    >
                      <Link
                        href={link.href}
                        onClick={
                          pathname !== '/'
                            ? (e) => handleHashLinks(e, link.href, router, searchParams)
                            : undefined
                        }
                        style={pathname === link.href ? navLinksStyle : {}}
                      >
                        {link.label}
                      </Link>
                      {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                    </Stack>

                    <Collapse in={openSubMenu} sx={{ width: '100%' }}>
                      <List component='div' disablePadding>
                        {productsNavLinks.map((productLink, subIdx) => (
                          <ListItem
                            key={subIdx}
                            onClick={() => {
                              // Navegar a la ruta del producto y cerrar el menú
                              router.push(productLink.href);
                              setOpenSubMenu(false);
                            }}
                            disablePadding
                          >
                            <CustomListItemText primary={productLink.label} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </Stack>
                </>
              ) : (
                <Link
                  href={link.href}
                  onClick={(e) =>
                    pathname !== '/'
                      ? handleHashLinks(e, link.href, router, searchParams)
                      : handleCloseDrawer()
                  }
                  style={pathname === link.href ? navLinksStyle : {}}
                >
                  {link.label}
                </Link>
              )}
            </div>
          ))}
        </Stack>
      </RevealAnimation>
    </>
  );
}
// styles
const navStyle: SxProps = {
  gap: 2.5,
  px: 5,
  py: 2,
  justifyContent: 'center',
  fontWeight: 'bold',
  width: '250px',
};

const navLinksStyle: CSSProperties = {
  borderLeft: '3px solid rgba(27, 129, 239, 1)',
  paddingLeft: '5px',
  position: 'relative',
};

// custom styled component
const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  fontWeight: 'bold',
  padding: '1px',
  '.mui-15yevto-MuiTypography-root': {
    fontSize: '14px', // Modifica el tamaño de fuente aquí
    fontWeight: 'bold',
  },
}));
