import '@/styles/CategoryProduct.css';
import { Check } from '@mui/icons-material';
import { Box, Stack, Typography, type SxProps } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

interface ProductCategoryProps {
  bgIdx: number;
  flexDirection: 'row' | 'row-reverse';
  imgSrc: string;
  objFit?: boolean;
  specs: string[];
  slug: string;
  subtitle: string;
  title: string;
  titleBg: string;
}

const RevealAnimation = dynamic(async () => {
  const { RevealAnimation } = await import('../common/RevealAnimation');
  return { default: RevealAnimation };
});

export function ProductCategory({
  bgIdx,
  imgSrc,
  flexDirection,
  objFit,
  specs,
  subtitle,
  title,
  titleBg,
  slug,
}: ProductCategoryProps) {
  // background-color options
  const bgColorOptions = ['primary.main', 'custom.moss', 'secondary.light', 'secondary.dark'];

  // styles
  const textStyled: SxProps = {
    background: `linear-gradient(45deg, white 30%, rgba(169, 169, 169, 1) 80%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  const mainStyles: SxProps = {
    display: 'flex',
    flexDirection: ['column', 'column', flexDirection],
    alignItems: 'center',
    justifyContent: 'space-evenly',
    rowGap: '5vh',
    columnGap: '5vw',
    width: '100%',
    padding: '1rem',
  };

  return (
    <Box component={'section'} bgcolor={bgColorOptions[bgIdx]}>
      <Stack className='nft' sx={{ height: '100%' }}>
        <Link href={`/products/category/${slug}`}>
          {/* Background Text */}
          <Box className={'bg'} sx={{ width: ['75vw', '90vw'] }}>
            <Typography
              variant='h1'
              fontSize={['20vw', '15vw', '9rem', '10rem']}
              fontWeight={800}
              textAlign={'center'}
            >
              {titleBg}
            </Typography>
          </Box>

          <Box className={'main product-category'} sx={mainStyles}>
            {/* Product image */}
            <Box maxWidth={'600px'}>
              <RevealAnimation>
                <Image
                  src={imgSrc}
                  alt={title}
                  width={1000}
                  height={750}
                  sizes='100vw'
                  loading='eager'
                  className='tokenImage'
                  style={{
                    width: '100vw',
                    maxWidth: 400,
                    height: 300,
                    margin: '0px auto',
                    objectFit: objFit ? 'contain' : 'cover',
                  }}
                />
              </RevealAnimation>
            </Box>
            {/* Product content preview */}
            <RevealAnimation width='fit-content'>
              <Stack spacing={2} className='txt'>
                <Typography variant='h3' fontWeight={700} sx={textStyled}>
                  {title}
                </Typography>
                <Typography variant='h5' fontWeight={500} sx={textStyled}>
                  {subtitle}
                </Typography>
                {specs.map((spec, idx) => (
                  <Stack key={idx} direction={'row'} spacing={1}>
                    <Check sx={{ color: 'white' }} />
                    <Typography paragraph color={'white'}>
                      {spec}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </RevealAnimation>
          </Box>
        </Link>
      </Stack>
    </Box>
  );
}
