'use client';

import { categoryInfo } from '@/helpers';
import { Grid } from '@mui/material';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

const ProductCard = dynamic(
  async () => await import('../reusable/ProductCard').then((module) => module.ProductCard)
);

export function ProductOverview() {
  // location
  const pathname = usePathname();
  // Extracts the slug from the current path
  const slug = pathname.replace('/products/category/', '');
  // Obtain information specific to the current category
  const currentCategoryInfo = categoryInfo.filter( item => item.category.toLowerCase() == slug.toLowerCase() ) || [];

  return (
    <>
      <Grid container rowGap={5} justifyContent={'center'} alignItems={'center'} columnGap={10}>
        {currentCategoryInfo.map((product, idx) => (
          <Grid key={idx} item>
            <ProductCard
              image={product.img}
              maxH={350}
              maxW='20rem'
              subtitle={product.subtitle}
              title={product.title}
              product={product}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
