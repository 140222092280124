'use client';
import { type ProductsInfo } from '@/helpers';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { type BaseSyntheticEvent } from 'react';
import 'yet-another-react-lightbox/styles.css';
import { ProductBody } from './ProductBody';

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  preventOnClose: (e: BaseSyntheticEvent) => void;
  product: ProductsInfo;
}

export function ProductModal({ isOpen, onClose, preventOnClose, product }: ProductModalProps) {

  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Dialog open={isOpen} onClose={preventOnClose} fullScreen={!upMd} maxWidth={'xl'}>
      <DialogTitle>
        <Stack alignItems={'end'}>
          <Button
            aria-label='modal close button'
            onClick={onClose}
            sx={{ height: '1.5rem', ':hover': { bgcolor: 'transparent' } }}
          >
            <Close sx={{ color: 'black', fontSize: '1.25rem' }} />
          </Button>
        </Stack>
        <Typography paragraph py={2} textAlign={'center'}>
          {`Información acerca de: ${product.title} ${product.subtitle}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ProductBody product={ product }/>
      </DialogContent>
    </Dialog>
  );
}