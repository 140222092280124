import { rootImages } from '@/core';

/**
 * Interface for product's information structure
 */
export interface ProductsInfo {
  short_description: string,
  description: string | string[];
  category: string,
  img: string;
  imgArr: string[];
  price: string | Record<string, string>;
  slug: string;
  subtitle: string;
  title: string;
}

/**
 * Information about product categories.
 * @type {Record<string, ProductsInfo[]>}
 */
export const categoryInfo: ProductsInfo[] = [
    {
      short_description: "Cable Drop 1 hilo 1 Km",
      description: [
        'Este cable drop de fibra óptica está diseñado para conexiones de corta distancia.',
        'Posee un solo hilo de fibra óptica y tiene una longitud de 1 kilómetro.',
        'Es adecuado para aplicaciones que requieren una conexión rápida y confiable, como redes domésticas o pequeñas empresas.',
        'Su diseño de un solo hilo lo hace ideal para conexiones simples y directas.',
      ],
      category: "Cables",
      img: rootImages.products.cables.drop1c1km1,
      imgArr: [
        rootImages.products.cables.drop1c1km1,
        rootImages.products.cables.drop1c1km,
        rootImages.products.cables.drop1c1km2,
        rootImages.products.cables.drop1c1km3,
        rootImages.products.cables.drop1h1km,
        rootImages.products.cables.drop1h1km1,
      ],
      price: '$80',
      slug: '1-hilo-1km',
      subtitle: '1 hilo 1 Km',
      title: 'Cable Drop',
    },
    {
      short_description: "Cable Drop 1 hilo 2 Km",
      description: [
        'Este cable drop también tiene un olo hilo de fibra óptica, pero su longitud es de 2 kilómetros.',
        'Es adecuado para aplicaciones que requieren una conexión de mayor alcance que el modelo de 1 km, pero aún se considera una conexión de corta distancia.',
        'Puede ser utilizado en entornos domésticos o comerciales para proporcionar conectividad confiable.',
      ],
      category: "Cables",
      img: rootImages.products.cables.drop1c2km,
      imgArr: [
        rootImages.products.cables.drop1c2km,
        rootImages.products.cables.drop1c2km1,
        rootImages.products.cables.cdrop1h2km2,
        rootImages.products.cables.drop1h2km,
        rootImages.products.cables.cdrop1h2km,
        rootImages.products.cables.drop1h2km1,
      ],
      price: '$130',
      slug: '1-hilo-2km',
      subtitle: '1 hilo 2 Km',
      title: 'Cable Drop'
    },
    {
      short_description: "Cable Drop 2 hilo 2 Km",
      description: [
        'A diferencia de los cables anteriores, este cable drop de fibra óptica tiene dos hilos de fibra óptica y una longitud de 2 kilómetros.',
        'Los dos hilos permiten una mayor capacidad de transmisión de datos o la posibilidad de establecer dos conexiones independientes.',
        'uede ser utilizado en situaciones donde se requiere redundancia de conexión o se necesita una mayor capacidad de transmisión en una conexión de corta distancia.',
      ],
      category: "Cables",
      img: rootImages.products.cables.drop2c2km,
      imgArr: [
        rootImages.products.cables.drop2c2km,
        rootImages.products.cables.drop2c2km1,
        rootImages.products.cables.drop2c2km2,
        rootImages.products.cables.drop2h2km,
        rootImages.products.cables.cdrop2h2km,
        rootImages.products.cables.drop2h2km1,
      ],
      price:'$140',
      slug: '2-hilo-2km',
      subtitle: ' 2 hilos 2 Km',
      title: 'Cable Drop',
    },
    {
      short_description: "Tenda AC11 - Router Wi-Fi Gigabit AC Doble Banda",
      description: [
        'El Tenda AC11 es un router Wi-Fi de alta velocidad con conectividad Gigabit y doble banda.',
        'Ofrece una conexión rápida y estable para una variedad de dispositivos, lo que lo convierte en una opción ideal para hogares y oficinas.',
        'Su capacidad de doble banda permite un rendimiento óptimo, ya que puede manejar tanto la banda de 2.4 GHz como la de 5 GHz.',
        'Con este router, disfrutarás de una experiencia de navegación fluida y una excelente cobertura Wi-Fi.',
      ],
      category: "Routers",
      img: rootImages.products.routers.ac11Tenda1,
      imgArr: [
        rootImages.products.routers.ac11Tenda1,
        rootImages.products.routers.ac11Tenda2,
        rootImages.products.routers.ac11Tenda3,
        rootImages.products.routers.ac11Tenda4,
        rootImages.products.routers.ac11Box,
        rootImages.products.routers.ac11Box1,
        rootImages.products.routers.ac11Comp,
      ],
      price: {
        retail: '$40',
        wholesale: '$35 (100 Unidades)',
      },
      slug: 'tenda-ac11',
      subtitle: 'Router Wi-Fi Gigabit AC Doble Banda',
      title: 'Tenda AC11',
    },
    {
      short_description: "Tenda MW6 - Router AC Mesh Gigabit Doble  Banda",
      description: [
        'El Tenda MW6 es un router de malla (mesh) de alto rendimiento con conectividad Gigabit y doble banda.',
        'La tecnología de malla permite una cobertura Wi-Fi completa en toda tu casa u oficina, eliminando las zonas muertas.',
        'Este router es ideal para hogares con múltiples dispositivos que requieren una conexión rápida y estable.',
        'Su capacidad de doble banda garantiza un rendimiento óptimo, lo que lo convierte en una solución confiable para todas tus necesidades de conectividad.',
      ],
      category: "Routers",
      img: rootImages.products.routers.meshMw6,
      imgArr: [
        rootImages.products.routers.meshMw6,
        rootImages.products.routers.meshMw6Box,
        rootImages.products.routers.meshMw6Box1,
        rootImages.products.routers.meshNova,
      ],
      price: '$100',
      slug: 'tenda-mw6',
      subtitle: 'Router AC Mesh Gigabit Doble  Banda',
      title: 'Tenda MW6',
    },
    {
      short_description: "ONU - Huawei HG8310M",
      description: [
        'La ONU (Unidad de Red Óptica) Huawei HG8310M es un dispositivo diseñado para la implementación de redes de fibra óptica.',
        'Especificamente, esta ONU se utiliza para la conexión de usuarios en redes de acceso de fibra óptica.',
        'Ofrece conectividad confiable y eficiente para la transmisión de datos a través de fibra óptica, lo que lo hace adecuado para su uso en entornos empresariales y residenciales. ',
        'La ONU Huawei HG8310M es una solución versátil para satisfacer las demandas de conectividad de alta velocidad en redes de fibra óptica.',
      ],
      category: "ONU",
      img: rootImages.products.onu.onuPrew,
      imgArr: [
        rootImages.products.onu.onuNew1,
        rootImages.products.onu.onuNew2,
        rootImages.products.onu.onuNew3,
        rootImages.products.onu.onuNew4,
        rootImages.products.onu.onu1,
        rootImages.products.onu.onuBox,
        rootImages.products.onu.onu,
      ],
      price: {
        retail: '$15',
        wholesale: '$14 (24 Unidades)',
      },
      slug: 'huawei-hg8310m',
      subtitle: 'Huawei HG8310M',
      title: 'ONU',
    },
    {
      short_description: "Caja Nap, Fiberhome GFS8G - 24 fusiones, 1x8 Splitter",
      description: [
        'Caja Nap, Marca: Fiberhome Modelo: GFS8G',
        '• 24 fusiones',
        '• Splitter 1x8 SC/APC',
        '• 8 acopladores APC',
        '• IP65',
        '• 2 entradas para cable Ø 6-12mm',
        '• Aplicación: Interior o Exterior',
        '• Material: PC + ABS',
        '• Temperatura de Operación: -40°C~+85°C',
        '• Instalación en poste o en pared',
      ],
      category: "FTTH",
      img: rootImages.products.ftth.napBoxGfs8g,
      imgArr: [
        rootImages.products.ftth.napBoxGfs8g,
        rootImages.products.ftth.napBoxGfs8g1,
        rootImages.products.ftth.napBoxGfs8g2,
        rootImages.products.ftth.napBoxGfs8g3,
        rootImages.products.ftth.napBoxGfs8g4,
        rootImages.products.ftth.napBoxGfs8g5,
        rootImages.products.ftth.napBoxGfs8g6,
      ],
      price: '$18',
      slug: 'caja-nap-gfs8g',
      subtitle: '24 fusiones, 1x8 Splitter',
      title: 'Caja Nap, Fiberhome GFS8G',
    },
    {
      short_description: "Caja Nap, ADC ADC-FDB416 - 16 fusiones, 1x16 Splitter",
      description: [
        'Caja Nap, Marca: ADC Modelo: ADC-FDB416',
        '• 16 fusiones',
        '• Splitter 1x16 SC/APC',
        '• 16 acopladores APC',
        '• IP66',
        '• 4 entradas para cable Ø 6-12mm',
        '• Aplicación: Interior o Exterior',
        '• Material: PC + ABS',
        '• Temperatura de Operación: -40°C~+85°C',
        '• Instalación en poste o en pared',
      ],
      category: "FTTH",
      img: rootImages.products.ftth.napBoxAdc,
      imgArr: [
        rootImages.products.ftth.napBoxAdc,
        rootImages.products.ftth.napBoxAdc1,
        rootImages.products.ftth.napBoxAdc2,
        rootImages.products.ftth.napBoxAdc3,
        rootImages.products.ftth.napBoxAdc4,
        rootImages.products.ftth.napBoxAdc5,
        rootImages.products.ftth.napBoxAdc6,
      ],
      price: '$30',
      slug: 'caja-nap-adc-fdb416',
      subtitle: '16 fusiones, 1x16 Splitter',
      title: 'Caja Nap, ADC ADC-FDB416',
    },
    {
      short_description: "Patchcord o Jumper - Patchcord SMF - Monomodo",
      description: [
        'Patchcord/Jumper',
        '• SIMPLEX',
        '• SMF (Monomodo) G.657.A2',
        '• 2 metros',
        '• SC/APC - SC/UPC',
      ],
      category: "FTTH",
      img: rootImages.products.ftth.patchcord,
      imgArr: [rootImages.products.ftth.patchcord],
      price: '$1.2',
      slug: 'patchcord',
      subtitle: 'Patchcord SMF (Monomodo)',
      title: 'Patchcord/Jumper',
    },
    {                     
      short_description: "Combo ONU, Roseta, Patchcord - Kit Completo Fibra Optica - FTTH",
      description: [
        'Pack de Conectividad de Alta Velocidad',
        '• ONU HUAWEI GH8310M',
        '• Roseta + 1 APC',
        '• Patchcord monomodo SC/APC-SC/UPC (2 metros)',
      ],
      category: "FTTH",
      img: rootImages.products.ftth.comboOnuRoseta,
      imgArr: [rootImages.products.ftth.comboOnuRoseta, rootImages.products.ftth.comboOnuRoseta1],
      price: '$16',
      slug: 'kit-fibra-optica',
      subtitle: 'Kit Completo Fibra Óptica',
      title: 'Combo ONU, Roseta, Patchcord',
    }
];
