/* eslint-disable @typescript-eslint/no-floating-promises */
'use client';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
  width?: 'fit-content' | '100%';
  height?: '' | '100%';
  overflow?: '' | 'hidden';
  yAxis?: number;
}

export function RevealAnimation({
  children,
  width = 'fit-content',
  height,
  overflow = 'hidden',
  yAxis = 75,
}: Props) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible');
    }
  }, [isInView]);

  return (
    <div ref={ref} style={{ position: 'relative', width, height, overflow }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: yAxis },
          visible: { opacity: 1, y: 0 },
        }}
        initial='hidden'
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {children}
      </motion.div>
    </div>
  );
}
