'use client';
import { rootImages } from '@/core';
import { KeyboardArrowDown, KeyboardArrowUp, Menu } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Stack,
  Toolbar,
  useMediaQuery,
  type Theme,
  Autocomplete,
  TextField,
  Drawer,
  Container,
  Fab
} from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter, useParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { categoryInfo } from '@/helpers';

import "@/styles/Navbar.css";

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

// dynamic imports
const Sidebar = dynamic(async () => await import('./Sidebar').then((module) => module.Sidebar), {
  ssr: false,
});
const BubbleButton = dynamic(
  async () => await import('../reusable/BubbleButton').then((module) => module.BubbleButton)
);
const ScrollTop = dynamic(
  async () => await import('../common/ScrollTop').then((module) => module.ScrollTop)
);
const ElevationScroll = dynamic(
  async () => await import('../common/ElevationScroll').then((module) => module.ElevationScroll)
);
const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function NavbarHome(props: Props) {
  
  const [value, setValue] = useState('');
  const [Options, setOptions] = useState([]);
  const [location, setLocation] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  // breakpoint
  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  // location
  const router = useRouter();
  const params = useParams();

  const toggleDrawer = () => setOpenDrawer(!openDrawer);

  const handleSubMenuClick = (event: any) => setSubMenuAnchorEl(event.currentTarget);

  const handleSubMenuClose = () => setSubMenuAnchorEl(null);
  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {

    if(newValue !== "" && newValue!.length >= 3){

      setValue(newValue?.toString().replace(".txt","") || '');
      router.push(`/search/${ encodeURIComponent(newValue  || '')}`);
    }
  };

  const handleTyping = (value: string) => {

      const itemsOptions:any = [];
      const filtered = categoryInfo.filter( item =>  item.short_description.toLowerCase().includes( value.toLowerCase() ));
     
      filtered.map( item =>  itemsOptions.push({ title: item.short_description }));
      setOptions(itemsOptions);

  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {

      if(value !== "" && value?.length >= 3){
        setValue(value.replace(".txt","") || '');
        router.push(`/search/${ encodeURIComponent(value) }`);
      }
    }
  };

  useEffect(() => {
    if(params.query){
      let query = params.query;
      setValue( decodeURIComponent(query.toString()) );
    }
  }, [params]);

  useEffect(() => {

    const hash = window.location.hash;

    if (hash) {
      const targetElement = document.querySelector(hash);

      if (targetElement) {
        window.scrollTo({
          behavior: 'smooth',
          top: targetElement.getBoundingClientRect().top + window.scrollY,
        });
      }
    }

    let location = window.location.href;
    setLocation(location);

  }, []);

  return (
    <>
      <ElevationScroll {...props}>
      <AppBar variant='elevation' sx={{ background: 'white', padding: '.5rem 1.5rem' }}>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'primary.dark',
            }}
          >
            <Link aria-label='logo' href={'/'}>
              <Stack maxWidth={'10rem'}>
                <Image
                  src={rootImages.logo.logotipo}
                  alt='Global Redex'
                  width={140}
                  height={70}
                  priority
                />
              </Stack>
            </Link>

            {upMd ? (
              <nav id="csMenu">
                <ul>
                  <li>
                    <Stack spacing={2} sx={{ width: 400 }} marginTop={2} marginRight={2} height={ "5px" }>
                      <Autocomplete
                        freeSolo
                        size="small"
                        value={value}
                        onChange={handleChange}
                        options={ Options.map( (option: any) => option.title ) }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Buscar, productos'
                            onKeyDown={handleKeyDown}
                            onChange={ (event) => handleTyping( event.target.value ) }
                          />
                        )}
                      />{' '}
                    </Stack>
                  </li>
                  <li>
                    <Link href="/" className={  window.location.pathname == "/" && !/#/.test(window.location.href) ?"active" : "" }>Inicio</Link>
                  </li>
                  <li>
                    <Link href="/about-us" className={ /about-us/.test(location) && !/#/.test(window.location.href)? "active" : "" }>Quiénes Somos</Link>
                  </li>
                  <li>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Link href="/products" style={{ display:"flex" }} className={ /products/.test(location)? "active" : "" }>
                        Productos <KeyboardArrowDown style={{ marginTop:"-3px" }}/>
                      </Link>
                    </Stack>
                    <ul>
                      <li>
                        <Link href="/products/category/cables">Cables Drop </Link>
                        <ul>
                          <li><Link href={`/products/${ encodeURIComponent("Cable Drop 1 hilo 1 Km") }`}>1 hilo 1 Km</Link></li>
                          <li><Link href={`/products/${ encodeURIComponent("Cable Drop 1 hilo 2 Km") }`}>1 hilo 2 Km</Link></li>
                          <li><Link href={`/products/${ encodeURIComponent("Cable Drop 2 hilo 2 Km") }`}>2 hilo 2 Km</Link></li>
                        </ul>
                      </li>
                      <li>
                        <Link href="/products/category/routers">Routers </Link>
                        <ul>
                          <li><Link  href={`/products/${ encodeURIComponent("Tenda AC11 - Router Wi-Fi Gigabit AC Doble Banda") }`}>Tenda AC11</Link></li>
                          <li><Link  href={`/products/${ encodeURIComponent("Tenda MW6 - Router AC Mesh Gigabit Doble  Banda") }`}>Tenda MW6</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="/products/category/onu">ONU </a>
                        <ul>
                          <li><Link href={`/products/${ encodeURIComponent("ONU - Huawei HG8310M") }`}>Huawei HG8310M</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="/products/category/ftth">FTTH </a>
                        <ul>
                          <li><Link href={`/products/${ encodeURIComponent("Caja Nap, Fiberhome GFS8G - 24 fusiones, 1x8 Splitter") }`}>Caja Nap, Fiberhome GFS8G</Link></li>
                          <li><Link href={`/products/${ encodeURIComponent("Caja Nap, ADC ADC-FDB416 - 16 fusiones, 1x16 Splitter") }`}>Caja Nap, ADC ADC-FDB416</Link></li>
                          <li><Link href={`/products/${ encodeURIComponent("Patchcord o Jumper - Patchcord SMF - Monomodo") }`}>Patchcord/Jumper</Link></li>
                          <li><Link href={`/products/${ encodeURIComponent("Combo ONU, Roseta, Patchcord - Kit Completo Fibra Optica - FTTH") }`}>Combo ONU, Roseta, Patchcord</Link></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li><Link href="/#services" className={ /services/.test(location)? "active" : "" }>Servicios</Link></li>
                  <li><Link href="/about-us/#contact" className={ /contact/.test(location) && /about-us/.test(location) ? "active" : "" }>Contacto</Link></li>
                </ul>
              </nav>
            ) : (
              <Button aria-label='open sidebar' onClick={toggleDrawer}>
                <Menu sx={{ color: 'black' }} />
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <Toolbar component={'span'} id='back-to-top-anchor' />

      <Drawer
        component={'aside'}
        anchor={'right'}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Sidebar handleCloseDrawer={toggleDrawer} />
      </Drawer>

      <Container>{props.children}</Container>

      <ScrollTop {...props}>
        <Fab size='small' aria-label='scroll back to top'>
          <KeyboardArrowUp sx={{ color: 'primary.main' }} />
        </Fab>
      </ScrollTop>
      <RevealAnimation>
        <BubbleButton />
      </RevealAnimation>
    </>
  );
}
