import { ProductsInfo } from "@/helpers";

export const zoomOptions: Record<string, number | boolean> = {
    maxZoomPixelRatio: 2,
    zoomInMultiplier: 5,
    doubleTapDelay: 300,
    doubleClickDelay: 500,
    doubleClickMaxStops: 2,
    keyboardMoveDistance: 50,
    wheelZoomDistanceFactor: 100,
    pinchZoomDistanceFactor: 100,
    scrollToZoom: true,
};

export function parseItemsToProduct ( items: any, search: string )   {

    let productList: ProductsInfo[] = [];

    items.map( (product: any) => {
        productList.push( product );
    })
    
    return productList;

}

export function removeAccent(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
  