import { WhatsApp } from '@mui/icons-material';
import { Fab } from '@mui/material';

export function BubbleButton() {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/584140594708', '_blank noreferrer noopener');
  };

  return (
    <Fab
      color='primary'
      aria-label='WhatsApp'
      style={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        backgroundColor: '#25D366',
      }}
      onClick={handleWhatsAppClick}
    >
      <WhatsApp />
    </Fab>
  );
}
