'use client';

import { Box, Fade, useScrollTrigger } from '@mui/material';
import React from 'react';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

export function ScrollTop(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window?.(),
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{ position: 'fixed', bottom: 100, right: 28, zIndex: 1 }}
      >
        {children}
      </Box>
    </Fade>
  );
}
