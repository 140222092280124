import { rootImages } from '@/core';
import { Box, Button, Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import Link from 'next/link';

// dynamic imports
const RevealAnimation = dynamic(async () => {
  const { RevealAnimation } = await import('../common/RevealAnimation');
  return { default: RevealAnimation };
});

const ReusableSlider = dynamic(async () => {
  const { ReusableSlider } = await import('../reusable/ReusableSlider');
  return { default: ReusableSlider };
});

// Slider images
const images = [
  { image: rootImages.products.cables.drop2c2km2, title: 'Cables Drop' },
  { image: rootImages.products.routers.ac11Tenda2, title: 'Router AC11' },
  { image: rootImages.products.onu.onuPrew, title: 'ONU' },
];

export function ProductsPreview() {
  return (
    <Box
      component={'section'}
      id='products'
      sx={{
        bgcolor: 'white',
        backgroundImage: 'linear-gradient(125deg, rgba(169,169,169,1) 0%, rgba(255,255,255,1) 20%)',
        color: 'black',
      }}
      rowGap={5}
      p={5}
    >
      <Stack
        direction={['column', 'column', 'row']}
        mx={'auto'}
        alignItems={'center'}
        justifyContent={'center'}
        columnGap={[0, 0, 10, 15]}
        maxWidth={'1200px'}
      >
        {/* Introduction section */}
        <Stack
          component={'article'}
          justifyContent={'flex-start'}
          maxWidth={['25rem', '30rem']}
          rowGap={4}
        >
          <RevealAnimation width='100%'>
            <Typography fontWeight={500} fontSize={['1.5em', '1.75em']}>
              Nuestros Productos
            </Typography>
          </RevealAnimation>

          <Stack spacing={1}>
            <RevealAnimation width='100%'>
              <Typography paragraph fontWeight={400}>
                Los productos de telecomunicaciones de alta calidad se destacan por su excepcional
                rendimiento y confiabilidad. Están diseñados con los más altos estándares para
                ofrecer conexiones de alta velocidad, transmisiones de datos impecables y una
                claridad de voz excepcional.
              </Typography>
            </RevealAnimation>
            <RevealAnimation width='100%'>
              <Typography fontWeight={500}>¿Quieres conocer más?</Typography>
            </RevealAnimation>
          </Stack>

          <RevealAnimation width='100%'>
            <Link href={'/products'}>
              <Button
                sx={{
                  bgcolor: 'primary.dark',
                  color: 'white',
                  ':hover': { bgcolor: 'primary.main', color: 'white' },
                  textTransform: 'capitalize',
                }}
              >
                Más productos
              </Button>
            </Link>
          </RevealAnimation>
        </Stack>

        {/* Products Slider section */}
        <ReusableSlider content={images} contentType='products' />
      </Stack>
    </Box>
  );
}
