import { Paper, Stack, Typography, type SxProps } from '@mui/material';

interface InfoCardProps {
  text: string;
  title: string;
}

export function CompanyInfoCard({ text, title }: InfoCardProps) {
  const containerStyled: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    color: 'black',
    bgcolor: 'white',
    borderRadius: 2,
    maxWidth: '45rem',
    minHeight: '16rem',
    height: '100%',
    p: 4,
    mx: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 1,
    ':hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 15px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    },
  };

  return (
    <Paper sx={containerStyled}>
      <Stack spacing={2} flexGrow={1} justifyContent={'space-evenly'} height={['10rem']}>
        <Typography fontSize={['1.1em', '1.3em']} fontWeight={500} color={'primary.main'}>
          {title}
        </Typography>

        <Typography color={'secondary.main'} paragraph fontSize={['1em', '1.2em']}>
          {text}
        </Typography>
      </Stack>
    </Paper>
  );
}
