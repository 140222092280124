'use client';
import { createTheme, responsiveFontSizes } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgba(27, 129, 239, 1)',
      dark: 'rgba(15, 83, 164, 1)',
    },
    secondary: {
      main: 'rgba(90, 90, 90, 1)',
      dark: 'rgba(66, 66, 66, 1)',
      light: 'rgba(169, 169, 169, 1)',
    },
    custom: {
      dark: 'rgba(25, 25, 25, 1)',
      moss: 'rgba(20, 60, 60, 1)',
      paleBlue: 'rgba(148, 171, 196, 1)',
      lightBlue: 'rgba(218, 255, 251, 1)',
    },
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: 16,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: 'rgba(148, 171, 196, 1)',
          }),
        }),
      },
    },
  },
});

export const responsiveTheme = responsiveFontSizes(theme);

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      dark: string;
      moss: string;
      paleBlue: string;
      lightBlue: string;
    };
  }
  interface PaletteOptions {
    custom: {
      dark: string;
      moss: string;
      paleBlue: string;
      lightBlue: string;
    };
  }
}
