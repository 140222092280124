"use client";

import { BaseSyntheticEvent, useState } from 'react';

import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { ProductsInfo } from '@/helpers';

interface ProductCardProps {
  image: string;
  maxH?: number | string;
  maxW?: string;
  onClick?: () => void;
  subtitle?: string;
  title: string;
  w?: string;
  inHome?: boolean;
  product?: ProductsInfo
}

// dynamic imports
const ProductModal = dynamic(
  async () => await import('../reusable/ProductModal').then((module) => module.ProductModal),
  {
    ssr: false,
  }
);

const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function ProductCard({
  image,
  maxH = 500,
  maxW = '30rem',
  onClick,
  subtitle,
  title,
  w = '85vw',
  inHome,
  product
}: ProductCardProps) {

  const [productObject, setProductObject] = useState(product);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductsInfo | null>(null);

  const handleProductClick = () => {
    if(productObject){
      setSelectedProduct(productObject);
    }
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const preventCloseBackdrop = (e: BaseSyntheticEvent, reason: string) => {
    reason === 'backdropClick' ? e.stopPropagation() : setModalOpen(false);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 3,
        width: 'fit-content',
        bgcolor: 'white',
        backgroundImage:
          'radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(169,169,169,1) 100%)',
      }}
    >
      <RevealAnimation width='100%'>
        <Stack width={w} maxWidth={maxW}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
              ':hover': {
                transform: 'scale(1.05)',
                transition: 'transform 0.3s ease',
              },
            }}
          >
            <Image
              src={image}
              alt={title}
              width={375}
              height={500}
              priority
              sizes='100vw'
              style={{
                width: '100%',
                height: maxH,
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                maxHeight: maxH,
                maxWidth: '375px',
                objectFit: 'contain',
              }}
            />
          </Box>

          <Stack spacing={1} py={2} justifyContent={'center'} alignItems={'center'} px={2}>
            <Typography
              fontSize={['1.1em', '1.25em']}
              color={'secondary.dark'}
              fontWeight={700}
              align='center'
            >
              {title}
            </Typography>

            <Typography color={'secondary.dark'} fontWeight={500} align='center'>
              {subtitle}
            </Typography>

            {!inHome && (
              <Button
                variant={'contained'}
                sx={{ bgcolor: 'primary.dark', ':hover': { bgcolor: 'primary.main' } }}
                onClick={ handleProductClick }
              >
                Expandir
              </Button>
            )}
          </Stack>
        </Stack>
      </RevealAnimation>

      {selectedProduct && (
        <ProductModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          preventOnClose={(e) => preventCloseBackdrop(e, 'backdropClick')}
          product={selectedProduct}
        />
      )}
    </Paper>
  );
}
