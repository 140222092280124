'use client';

import { useScrollTrigger } from '@mui/material';
import React from 'react';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

export function ElevationScroll(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window?.(),
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
