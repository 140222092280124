'use client';
import { Box, Typography, useMediaQuery, type SxProps, type Theme } from '@mui/material';
import dynamic from 'next/dynamic';

interface BannerProps {
  img: string;
  subtitle?: string;
  title: string;
  inHome: boolean;
}

const RevealAnimation = dynamic(
  async () => await import('../common/RevealAnimation').then((module) => module.RevealAnimation)
);

export function Banner({ img, subtitle, title, inHome }: BannerProps) {
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const styleBannerContainer: SxProps = {
    /* position: 'relative', */
    /* marginTop:"30px", */
    minHeight: inHome ? (!upSm ? '50vh' : '70vh') : '50vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const styleBannerImage: SxProps = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    filter: 'brightness(0.5)', // Aplicar desenfoque solo a la imagen
    zIndex: -1,
  };

  const styleContent: SxProps = {
    display: 'flex',
    rowGap: 4,
    flexDirection: 'column',
    height: '100%',
    color: '#ffffff',
  };

  return (
    <Box component={'section'} sx={styleBannerContainer} marginTop={ 10 }>
      <Box sx={styleContent}>
        <RevealAnimation width='100%'>
          <Typography
            textAlign={'center'}
            variant={'h1'}
            fontWeight={700}
            color={'white'}
            fontSize={['2.5em', '4.5em']}
          >
            {title}
          </Typography>
        </RevealAnimation>
        <RevealAnimation width='100%'>
          <Typography
            textAlign={'center'}
            variant={'h2'}
            fontWeight={400}
            color={'white'}
            fontSize={['1.3em', '1.5em', '1.75em']}
          >
            {subtitle}
          </Typography>
        </RevealAnimation>
        <Box sx={styleBannerImage} />
      </Box>
    </Box>
  );
}
