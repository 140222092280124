'use client';
import { rootImages } from '@/core';
import { Box, Divider, Stack, Typography, styled } from '@mui/material';
import dynamic from 'next/dynamic';
import Image from 'next/image';

const RevealAnimation = dynamic(async () => {
  const { RevealAnimation } = await import('../common/RevealAnimation');
  return { default: RevealAnimation };
});

export function OurTeam() {
  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) ~ :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  return (
    <Box
      p={3}
      color={'black'}
      component={'section'}
      mx={'auto'}
      sx={{
        backgroundImage: 'linear-gradient(125deg, rgba(169,169,169,1) 0%, rgba(255,255,255,1) 40%)',
      }}
    >
      <Stack spacing={6} alignItems={'center'} justifyContent={'center'}>
        <Root>
          <Divider>
            <RevealAnimation width='100%'>
              <Image src={rootImages.logo.logotipo} alt='Global Redex' width={180} height={90} />
            </RevealAnimation>
          </Divider>
        </Root>

        <RevealAnimation width='100%'>
          <Typography variant='h6' fontWeight={400} fontStyle={'italic'} align={'center'}>
            Nuestro equipo profesional
          </Typography>
        </RevealAnimation>

        <Stack
          direction={{ md: 'row' }}
          rowGap={2}
          columnGap={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <RevealAnimation width='fit-content'>
            <Image
              src={rootImages.aboutUs.team}
              alt='Global-Redex-Team'
              width={800}
              height={600}
              sizes='100vw'
              style={{
                minWidth: '15rem',
                width: '80vw',
                maxWidth: '40rem',
                minHeight: 300,
                height: 'auto',
                maxHeight: 500,
                objectFit: 'cover',
              }}
            />
          </RevealAnimation>

          <Box width={'fit-content'} maxWidth={'700px'} px={[1, 5]}>
            <RevealAnimation width='100%'>
              <Typography paragraph fontSize={['1em', '1.1em', '1.3em']}>
                En nuestra empresa de servicios y suministros de telecomunicaciones, nos enorgullece
                ofrecer materiales de alta calidad para las necesidades de telecomunicaciones de
                nuestros clientes. Nos especializamos en la venta de equipos y componentes de
                telecomunicaciones, brindando soluciones confiables y eficientes para redes de
                comunicación.
              </Typography>
            </RevealAnimation>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
